"use client";

import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "next/image";
import logo from "@/app/images/dinheiro.png";
import { parseCookies } from "nookies";


const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { token } = parseCookies();
  const router = useRouter();

  useEffect(() => {
    if (token) {
      const checkToken = async () => {
        const response = await fetch("/api/me", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.ok) {
          router.push("/pages/home");
        } else {
          router.push("/");
        }
      };

      checkToken();
    }
  }, [token, router]);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: usuario, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.hash;

        if (!token || token === "invalid credentials") {
          setError("Usuário ou senha inválidos");
          return;
        }

        const date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 1 dia em milissegundos
        const expires = "expires=" + date.toUTCString();
        document.cookie = `user_logged=loggedin; ${expires}; path=/`;
        document.cookie = `token=${token}; ${expires}; path=/`;

        router.push("/pages/home");
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Erro ao fazer login");
      }
    } catch (err) {
      setError("Erro ao fazer login");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 border">
      <form
        className="container w-75 border rounded p-5 bg-light"
        onSubmit={handleLogin}
      >
        <div className="mb-3 d-flex justify-content-center">
          <Image src={logo} alt="logo" width="100" height="100" />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingUsuario"
            placeholder="Usuário"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
          />
          <label htmlFor="floatingUsuario">Usuário</label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
